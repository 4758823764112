<template>
  <div class="card">
    <h1>{{ event.name }}</h1>
    <div>Name: {{ event.name }}</div>
    <div>Region: {{ event.region }}</div>
    <div>Description: {{ event.description }}</div>
    <div>From: {{ event.date_start }}</div>
    <div>To: {{ event.date_end }}</div>
    <div>Capacity: {{ event.capacity }}</div>
    <div>Price: {{ event.price }}</div>
    <div>Lodge: {{ event.lodge.name }}</div>

    <h2>Clients attending</h2>
    <client-list
      :clients="event.client"
      :should-show-toolbar="false"
    ></client-list>
  </div>
</template>

<script>
import ClientList from '@/components/ClientList.vue'
import useEvent from '@/use/useEvent.js'
import { reactive } from 'vue'
import { mapLevelToSeverity } from '@/helpers/clientHelpers.js'

export default {
  components: { ClientList },
  props: {
    eventId: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const eventState = reactive({})
    const { fetchEvent } = useEvent()

    eventState.event = await fetchEvent(props.eventId)

    return {
      event: eventState.event,
      mapLevelToSeverity
    }
  }
}
</script>
