<template>
  <Suspense>
    <EventView :event-id="eventId" />
  </Suspense>
</template>

<script>
import EventView from '@/components/EventView.vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    EventView
  },
  setup() {
    const route = useRoute()
    const eventId = route.params.id

    return {
      eventId
    }
  }
}
</script>
